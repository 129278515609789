<form class="w-100" [formGroup]="verificationCodeForm">
  <div class="d-flex ion-align-items-center ion-justify-content-center">
    <ion-text color="blue-60" class="op-body-small ion-text-center fw-600">
      {{ 'account.verificationForm.verificationNote' | translate}}
    </ion-text>
  </div>
  <div class="d-flex flex-row ion-align-items-center ion-justify-content-center mb-5">
    <ng-otp-input [config]="verificationCodeConfig" [formCtrl]="codeFormControl"></ng-otp-input>
  </div>

  <div class="d-flex flex-row ion-align-items-center ion-justify-content-center mb-5">
    <div class="w-100 op-separator ion-text-center">
      <ion-text class="op-body-small d-inline-block w-100 mb-2 mt-2" color="black-60">{{ 'account.or' | translate}}</ion-text>
    </div>
  </div>

  <app-primary-button class="w-100" type="submit" (click)="resendCode()">{{ 'account.verificationForm.resendCode' | translate}}</app-primary-button>
</form>
