import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { AlertService } from './alert.service';

@NgModule({
  imports: [IonicModule],
  providers: [AlertService],
})
export class AlertModule {}
