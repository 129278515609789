import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthModalTypes } from '@op-types/auth.model';
import { User } from '@op-types/user.model';
import { from, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication';
import { AuthCardModalComponent } from '../auth-card-modal/auth-card-modal.component';
import { headerMenudata } from  './header.menu.data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuItems = headerMenudata;

  user$: Observable<null | User.Entity>;

  constructor(private modalController: ModalController, private authenticationService: AuthenticationService) {}

  get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  ngOnInit(): void {
    this.user$ = this.authenticationService.selectUser();
  }

  /* istanbul ignore next */
  openLoginModal(): void {
    this.openCardModal(AuthModalTypes.SIGN_IN);
  }

  /* istanbul ignore next */
  openSignUpModal(): void {
    this.openCardModal(AuthModalTypes.SIGN_UP);
  }

  /* istanbul ignore next */
  private openCardModal(authModalType: AuthModalTypes): void {
    from(
      this.modalController.create({
        animated: false,
        component: AuthCardModalComponent,
        canDismiss: true,
        cssClass: 'op-card-modal',
        componentProps: { authModalType },
      }),
    )
      .pipe(
        first(),
        tap((modalElement: HTMLIonModalElement) => modalElement.present()),
      )
      .subscribe();
  }
}
