import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { User } from '@op-types/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserQuery extends Query<User.QueryResponse> {
  document = gql` query { user: me { id,  fullName,  firstName,  lastName,  email,  contactNumber,  hasVerifiedEmail,  profilePercent, trialSubscription, healthyVolunteer,
    isSocialAccount,    birthCountry,    preferredLanguage,    englishLevel,    sharingConsent,    trialPreference {
      dob,
      age,
      gender,
      travelDistance,
      medicalConditions,
      location
    },photoTiny: photo(size: TINY), photoThumb: photo(size: THUMB), company { name }, crmToken { token }}}`;
}
