import { NgModule } from '@angular/core';
import { APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { AuthenticationService } from './services/authentication';
import { environment } from '../environments/environment';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
const uri = `${environment.apiURL}/graphql`;

export function createApollo(httpLink: HttpLink, authService: AuthenticationService): ApolloClientOptions<any> {
  const basic = setContext(() => ({
    headers: {
      Accept: 'charset=utf-8',
      'locale': localStorage.getItem('lang') ? localStorage.getItem('lang') : environment.lang,
    },
  }));

  const auth = setContext(() => {
    const token = authService.getToken();
    return authService.getGraphQLHeaders();
  });

  const link = ApolloLink.from([basic, auth, createUploadLink({ uri })]);

  return {
    link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true,
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthenticationService],
    },
  ],
})
export class GraphQLModule {
}
