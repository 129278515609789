import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() errorMessages: { [errorKey: string]: string };
  @Input() defaultNote: string = '';
  /* istanbul ignore next */
  @Input()
  set control(payload: FormControl | AbstractControl) {
    this.formControl = payload;
  }

  get control(): FormControl | AbstractControl {
    return this.formControl;
  }

  @Input() classList: string | string[];

  private formControl: FormControl | AbstractControl;
}
