import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthModalTypes } from '@op-types/auth.model';
import { User } from '@op-types/user.model';
import { finalize, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services/authentication';
import { SignInService } from '../../services/sign-in';
import { AuthCardModalComponent } from '../auth-card-modal/auth-card-modal.component';
import { headerMenudata } from '../header/header.menu.data';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menuItems = headerMenudata;

  user$: Observable<null | User.Entity>;

  constructor(
    private router: Router,
    private signInService: SignInService,
    private menuController: MenuController,
    private modalController: ModalController,
    private authenticationService: AuthenticationService,
  ) {
  }

  get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  ngOnInit(): void {
    this.user$ = this.authenticationService.selectUser();
  }

  closeMenu(): Promise<boolean> {
    return this.menuController.close();
  }

  logout(): void {
    this.signInService
      .signOut()
      .pipe(
        switchMap(() => this.authenticationService.setToken(null)),
        switchMap(() => this.closeMenu()),
        tap(() => this.authenticationService.setUser(null)),
        finalize(() => {
          let landingPage = localStorage.getItem('landingPage') ?? this.router.url;
          if (landingPage == this.router.url && this.router.url.includes('/account')) {
            landingPage = '/home';
          }
          this.router.navigateByUrl(landingPage);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /* istanbul ignore next */
  openSignUpCard(): void {
    this.openModal(AuthModalTypes.SIGN_UP);
  }

  /* istanbul ignore next */
  openLoginCard(): void {
    this.openModal(AuthModalTypes.SIGN_IN);
  }

  /* istanbul ignore next */
  private async openModal(authModalType: AuthModalTypes): Promise<void> {
    await this.closeMenu();

    const modalElement = await this.modalController.create({
      component: AuthCardModalComponent,
      canDismiss: true,
      cssClass: 'op-mobile-card-modal',
      componentProps: {authModalType},
    });

    await modalElement.present();
  }
}
