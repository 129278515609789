import { Component, Inject, HostListener, OnDestroy, OnInit } from '@angular/core';
//import {StatusBar} from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
//import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MetaService } from './services/SEO/meta.service';
import { TrackingService } from './services/tracking/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { register } from 'swiper/element/bundle';

register();

declare var gtag: any;
declare var fbq: any;
declare var saq: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // istanbul ignore next
  languageSettings: string[] = ['en', 'es', 'zh-tw', 'ko'];

  pageView: any;
  scrollTimeout: any;
  scrollLeft: number = 0;
  scrollTop: number = 0;
  trackingInitialed: boolean = false;
  unloadFired: boolean = false;

  constructor(
    private platform: Platform,
    //private statusBar: StatusBar,
    //private splashScreen: SplashScreen,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private meta: MetaService,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) {

    this.initializeApp();

  }

  liveChat: boolean = false

  ngOnInit() {

    this.getGoogleTrackingCode(window, document, 'script', 'dataLayer', 'GTM-PLMH94H');
    setTimeout(() => {
      this.liveChat = true;
      this.getBrevoData(document, window, 'BrevoConversations');
    }, 2000);
  }

  getGoogleTrackingCode(w: any, d: any, s: string, l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  getBrevoData(d: any, w: any, c: string) {
    w.BrevoConversationsID = '64ae578b2d05c503fd57d7e0';
    w[c] = w[c] || function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
    var s = d.createElement('script');
    s.async = true;
    s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
    if (d.head) d.head.appendChild(s);
  }

  @HostListener('document:focusout', ['$event'])
  onBlur(event: FocusEvent) {
    this.trackingService.emitFouseOut(event);
  }

  @HostListener('window:click', ['$event'])
  onClick(event: any) {
    this.trackingService.emitClick(event);
  }

  @HostListener('window:pagehide', ['$event'])
  onPagehide(event: any) {
    if (!this.unloadFired) {
      this.unloadFired = true;
      this.trackingService.emitUnload(event);
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilityChangeHandler(event: Event) {
    if (document.visibilityState === 'hidden') {
      if (!this.unloadFired) {
        this.unloadFired = true;
        this.trackingService.emitUnload(event);
      }
    }
  }

  // istanbul ignore next
  initializeApp() {
    this.meta.setMetaFromRouter();
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
      this.activeRouter.queryParams
        .subscribe(query => {
          if (query.cmp && !Array.isArray(query.cmp) && query.cmp.length < 50) {
            localStorage.setItem('campaign', query.cmp);
          }

          let lang = localStorage.getItem('lang') ?? environment.lang; //this.translate.getBrowserLang();

          if (query.lang && this.languageSettings.indexOf(query.lang) > -1 && query.lang != lang) {
            lang = query.lang;
            //Cache the landing page for each user if not using english and remove it if using English
            if (lang != 'en') {
              localStorage.setItem('landingPage', this.router.url);
            }
          }

          //remove the landing page for each user if using english
          if (lang == 'en' && localStorage.getItem('landingPage')) {
            localStorage.removeItem('landingPage');
          }

          // the lang to use, if the lang isn't available, it will use the current loader to get them
          if (lang != localStorage.getItem('lang') && this.languageSettings.indexOf(lang) > -1) {
            localStorage.setItem('lang', lang);
          }

          this.translateService.setDefaultLang(lang);
        });
      this.trackSite();
    });
  }

  // istanbul ignore next
  trackSite() {
    const navEndEvents = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)) as Observable<NavigationEnd>;

    navEndEvents.subscribe((event: NavigationEnd) => {
      this.meta.setMetaFromRouter();
      const data = {
        page_path: event.urlAfterRedirects,
      };
      fbq('track', 'PageView', data);
      setTimeout(() => {
        if (this.trackingInitialed) {
          this.trackingService.emitNewPage(event.url);
        } else {
          this.trackingService.setPreviousUrl(document.referrer);
          this.trackingService.initalData();
          this.trackingInitialed = true;
        }
        this.trackingService.setPreviousUrl(event.url);
      }, 100);

    });
  }

  ngOnDestroy() {
    if (!this.unloadFired) {
      this.unloadFired = true;
      this.trackingService.emitUnload(event);
    }
  }
}
