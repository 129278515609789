import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-form-field-error-messages',
  templateUrl: './form-field-error-messages.component.html',
  styleUrls: ['./form-field-error-messages.component.scss'],
})
export class FormFieldErrorMessagesComponent {
  @Input() errors: ValidationErrors | null;
  @Input() errorMessages: { [errorKey: string]: string };

  /* istanbul ignore next */
  get firstError(): string | null {
    return this.errors && Object.keys(this.errors)?.length ? Object.keys(this.errors)[0] : null;
  }
}
