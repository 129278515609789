import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { User } from '@op-types/index';

@Injectable({
  providedIn: 'root',
})
export class VerifyUserMutation extends Mutation<User.Verify.MutationResponse, User.Verify.MutationVariables> {
  /* istanbul ignore next */
  document = gql`mutation VerifyUser($token: String!) {verifyUser(token: $token) { success, message}}`;
}
