import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { LinkedinService } from './linkedin.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [LinkedinService],
})
export class LinkedinModule {}
