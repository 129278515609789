import { Injectable} from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController, private translate: TranslateService) {}

  /* istanbul ignore next */
  async presentToast(message: string, duration = 2000) {
    if (message.indexOf(' ') == -1) {
      this.translate.get('toastService.'+message).subscribe((data:any)=> {
        message = data;
      });
    }

    const toast = await this.toastController.create({
      message,
      duration,
    });
    toast.present();
  }
}
