import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { Register } from '@op-types/index';

@Injectable({
  providedIn: 'root',
})
export class RegisterMutation extends Mutation<Register.Response, Register.Variables> {
  document = gql` mutation Register( $firstName: String!, $lastName: String!, $email: String!, $contactNumber: String!, $password: String!, $deviceName: String!, $trialSubscription: Boolean, $company: String, $companyType: String) { register(inputs: { firstName: $firstName, lastName: $lastName, email: $email, contactNumber: $contactNumber, deviceName: $deviceName, company: $company, companyType: $companyType, password: $password, trialSubscription:$trialSubscription } ) { token, user { id,  fullName,  firstName,  lastName,  email,  contactNumber,  hasVerifiedEmail,  profilePercent, trialSubscription, photoTiny: photo(size: TINY), photoThumb: photo(size: THUMB), company { name }, crmToken { token }}}}`;
}
