import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, first, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { AuthModalTypes } from '@op-types/auth.model';
import { ForgotPasswordService } from '../../services/forgot-password/forgot-password.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  @Output() email$ = new Subject<string>();
  @Output() switchAuthModalType$ = new Subject<AuthModalTypes>();

  forgotPasswordForm: FormGroup;
  emailErrorMessage: Record<string, string> = {
    required: 'form.errors.required',
    email: 'form.errors.email',
    account: 'form.errors.account',
  };

  constructor(private fb: FormBuilder, private forgotPasswordService: ForgotPasswordService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  onSubmit(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const { email } = this.forgotPasswordForm.getRawValue();

    this.forgotPasswordService
      .sendVerificationCode(email)
      .pipe(
        first(),
        tap(() => {
          this.email$.next(email);
          this.switchAuthModalType$.next(AuthModalTypes.VERIFICATION_CODE);
        }),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => {
            const emailControl = this.forgotPasswordForm.get('email');

            if (emailControl) {
              emailControl.setValue('');
              emailControl.setErrors({ account: true });
            }

            // @ts-ignore
            this.emailErrorMessage.account = error?.message ? 'form.errors.account' : 'form.errors.email';

            return of();
          },
        ),
      )
      .subscribe();
  }
}
