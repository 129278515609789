import { AuthModalHeader, AuthModalTypes } from '@op-types/auth.model';

export const AUTH_MODAL_TYPE_HEADERS: { [modalType: string]: AuthModalHeader } = {
  [AuthModalTypes.SIGN_IN]: {
    logo: true,
    header: 'account.loginForm.Header',
    subheader: 'account.loginForm.SubHeader',
  },
  [AuthModalTypes.SIGN_UP]: {
    logo: false,
    header: 'account.signupForm.Header',
    subheader: 'account.signupForm.SubHeader',
  },
  [AuthModalTypes.FORGOT_PASSWORD]: {
    logo: true,
    header: 'account.forgotPasswordForm.Header',
    subheader: 'account.forgotPasswordForm.SubHeader',
  },
  [AuthModalTypes.VERIFICATION_CODE]: {
    logo: false,
    header: 'account.verificationForm.Header',
    subheader: 'account.verificationForm.SubHeader',
  },
  [AuthModalTypes.UPDATE_PASSWORD]: {
    logo: true,
    header: 'account.updatePasswordForm.Header',
    subheader: 'account.updatePasswordForm.SubHeader',
  },
};
