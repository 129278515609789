<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-padding">
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button class="ion-no-padding ion-no-margin" color="black-60" (click)="closeModal()">
        <ion-icon size="large" name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-align-items-center d-flex">
      <ion-col size="12" class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column">
        <div class="op-confirm-email d-flex">
          <div class="op-confirm-email__icon">
            <ion-icon name="alert-circle-outline"></ion-icon>
          </div>
          <div class="op-confirm-email__control">
            <ion-text class="d-block op-subheading fw-600 mb-1">{{ 'account.confirmEmailForm.Header' | translate }}</ion-text>
            <ion-text class="d-block op-body mb-2">{{ 'account.confirmEmailForm.SubHeader' | translate }}</ion-text>
            <ion-button class="ion-no-margin fw-600" fill="clear" color="blue" (click)="resentUserVerificationEmail()">
              {{ 'account.confirmEmailForm.resendEmail' | translate }}
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
