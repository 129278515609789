<ion-row class="ion-align-items-center d-flex">
  <ion-col size="12" class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column">
    <app-outline-button expand="block" class="w-100 mb-3" (click)="loginWithGoogle()">
      <ion-icon name="google-logo" class="me-3 op-social-logo"></ion-icon>
      <ion-text class="op-body fw-600 mb-0" color="black-80">{{ 'account.googleLogin' | translate }}</ion-text>
    </app-outline-button>
    <app-outline-button expand="block" class="w-100 mb-3" (click)="loginWithFacebook()">
      <ion-icon name="facebook-logo" class="me-3 op-social-logo"></ion-icon>
      <ion-text class="op-body fw-600 mb-0" color="black-80">{{ 'account.facebookLogin' | translate }}</ion-text>
    </app-outline-button>
    <app-outline-button expand="block" class="w-100" (click)="loginWithLinkedIn()">
      <ion-icon name="linkedin-logo" class="me-3 op-social-logo"></ion-icon>
      <ion-text class="op-body fw-600 mb-0" color="black-80">{{ 'account.linkedInLogin' | translate }}</ion-text>
    </app-outline-button>
  </ion-col>
</ion-row>
