<form class="w-100 op-login-form" [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
  <app-form-field
    classList="mb-2"
    [control]="updatePasswordForm?.controls?.password"
    [errorMessages]="passwordErrorMessages"
  >
    <ion-label position="stacked">
      {{ 'account.password' | translate }}<span appAsterixMark controlName="password" [formGroup]="updatePasswordForm"></span>
    </ion-label>
    <ion-input placeholder="" type="password" formControlName="password" appTogglePasswordAppearance></ion-input>
    <ion-button slot="end" fill="clear" color="black-50">
      <ion-icon slot="icon-only" color="black-50" name="eye-off-outline"></ion-icon>
    </ion-button>
  </app-form-field>
  <app-form-field
    classList="mb-2"
    [control]="updatePasswordForm?.controls?.confirmPassword"
    [errorMessages]="confirmPasswordErrorMessages"
  >
    <ion-label position="stacked">
      {{ 'account.confirmPassword' | translate }}<span appAsterixMark controlName="confirmPassword" [formGroup]="updatePasswordForm"></span>
    </ion-label>
    <ion-input placeholder="" type="password" formControlName="confirmPassword" appTogglePasswordAppearance></ion-input>
    <ion-button slot="end" fill="clear" color="black-50">
      <ion-icon slot="icon-only" color="black-50" name="eye-off-outline"></ion-icon>
    </ion-button>
  </app-form-field>
  <div class="mt-6 w-100">
    <app-primary-button class="w-100" expand="block" type="submit">{{ 'form.buttonText.savePassword' | translate }}</app-primary-button>
  </div>
</form>
