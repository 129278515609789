import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() type = 'button';
  @Input() slot: string;
  @Input() disabled: boolean;
  @Input() expand: 'block' | 'full' = 'block';
  @Input() size: 'small' | 'default' | 'large' = 'default';
  // to add extra color, add color palette in variable.scss
  @Input() color: 'blue' | 'peach' = 'blue';
  @Input() classList: string | string[] | Set<string> | { [c: string]: any };
}
