import { Component, OnInit } from '@angular/core';
import { SocialUser } from '@abacritt/angularx-social-login';
import { SignInService } from '@op-app/services/sign-in';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {

  constructor(private signInService: SignInService) { }

  ngOnInit() {}


  loginWithFacebook(): Promise<SocialUser> {
    return this.signInService.loginWithFacebook();
  }

  loginWithGoogle(): Promise<SocialUser> {
    return this.signInService.loginWithGoogle();
  }

  loginWithLinkedIn(): void {
    this.signInService.loginWithLinkedIn();
  }


}
