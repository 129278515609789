<form class="w-100 op-sign-up-form mt-2" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
  <app-form-field [control]="signUpForm?.controls?.firstName" [errorMessages]="firstNameErrorMessages">
    <ion-label class="op-input-label" position="stacked">
      {{ 'account.profileText.firstName' | translate }}<span appAsterixMark controlName="firstName" [formGroup]="signUpForm"></span>
    </ion-label>
    <ion-input id="firstName" [name]="'firstName'" placeholder="{{'account.profileText.firstName' | translate}}" type="text" formControlName="firstName"></ion-input>
    <ion-icon slot="start" name="person-outline"></ion-icon>
  </app-form-field>
  <app-form-field [control]="signUpForm?.controls?.lastName" [errorMessages]="firstNameErrorMessages">
    <ion-label class="op-input-label" position="stacked">
      {{ 'account.profileText.lastName' | translate }}<span appAsterixMark controlName="lastName" [formGroup]="signUpForm"></span>
    </ion-label>
    <ion-input id="lastName" [name]="'lastName'" placeholder="{{ 'account.profileText.lastName' | translate }}" type="text" formControlName="lastName"></ion-input>
    <ion-icon slot="start" name="person-outline"></ion-icon>
  </app-form-field>
  <div
    class="op-tel-input mt-2"
    [class.op-tel-input--error]="
      signUpForm?.controls?.contactNumber?.touched && signUpForm?.controls?.contactNumber?.errors
    "
  >
    <ion-label class="op-input-label" position="stacked">
      {{ 'account.profileText.contactNumber' | translate }}<span appAsterixMark controlName="contactNumber" [formGroup]="signUpForm"></span>
    </ion-label>
    <ngx-intl-tel-input
      [separateDialCode]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="true"
      [enableAutoCountrySelect]="true"
      [selectFirstCountry]="false"
      [phoneValidation]="true"
      [numberFormat]="phoneNumberFormat"
      [selectedCountryISO]="selectedCountry"
      [searchCountryField]="searchCountryField"
      formControlName="contactNumber"
      [id]="'contactNumber'"
    >
    </ngx-intl-tel-input>
    <app-form-field-error-messages
      *ngIf="signUpForm?.controls?.contactNumber?.touched && signUpForm?.controls?.contactNumber?.errors"
      [errors]="signUpForm.controls.contactNumber.errors"
      [errorMessages]="contactNumberErrorMessages"
    ></app-form-field-error-messages>
  </div>
  <div>
    <app-form-field [control]="signUpForm?.controls?.email">
      <ion-label class="op-input-label" position="stacked">
        {{ 'account.profileText.email' | translate }}<span appAsterixMark controlName="email" [formGroup]="signUpForm"></span>
      </ion-label>
      <ion-input id="email" [name]="'email'" placeholder="example@email.com" type="email" formControlName="email"></ion-input>
      <ion-icon slot="start" name="mail-outline"></ion-icon>
    </app-form-field>
    <app-form-field-error-messages
      *ngIf="signUpForm?.controls?.email?.touched && signUpForm?.controls?.email?.errors"
      [errors]="signUpForm.controls.email.errors"
      [errorMessages]="emailErrorMessages"
    ></app-form-field-error-messages>
    <ion-text
      *ngIf ="signUpForm?.controls?.email?.touched && signUpForm?.controls?.email?.errors?.validation_email == 'form.errors.unique'"
      class="noSpace op-form-field__error fw-600 op-text-link"
      (click)="switchToLogin.emit()"
      color="blue">&nbsp;{{ 'account.login1' | translate }}</ion-text>
  </div>
  <app-form-field [control]="signUpForm?.controls?.password" [errorMessages]="passwordErrorMessages" [defaultNote] = "'account.passwordNote' | translate">
    <ion-label position="stacked">
      {{ 'account.password' | translate }}<span appAsterixMark controlName="password" [formGroup]="signUpForm"></span>
    </ion-label>
    <ion-input placeholder="" [name]="'password'" type="password" formControlName="password"  appTogglePasswordAppearance></ion-input>
    <ion-button slot="end" fill="clear" color="black-50">
      <ion-icon slot="icon-only" color="black-50" name="eye-off-outline"></ion-icon>
    </ion-button>
  </app-form-field>
  <app-form-field
    classList="mb-0"
    [control]="signUpForm?.controls?.confirmPassword"
    [errorMessages]="confirmPasswordErrorMessages"
    *ngIf="(signUpForm?.controls?.password?.touched && !signUpForm?.controls?.password?.errors) || (signUpForm?.controls?.email?.touched && !signUpForm?.controls?.email?.errors)"
  >
    <ion-label position="stacked">
      {{ 'account.confirmPassword' | translate }}<span appAsterixMark controlName="confirmPassword" [formGroup]="signUpForm"></span>
    </ion-label>
    <ion-input placeholder="" [name]="'confirmPassword'" type="password" formControlName="confirmPassword" appTogglePasswordAppearance></ion-input>
    <ion-button slot="end" fill="clear" color="black-50">
      <ion-icon slot="icon-only" color="black-50" name="eye-off-outline"></ion-icon>
    </ion-button>
  </app-form-field>
  <ion-list class="ion-no-padding mt-2 mb-2">
    <ion-item lines="none" class="w-100 d-flex ion-align-items-center ion-justify-content-between">
      <ion-checkbox slot="start" formControlName="agreement" [name]="'agreement'"></ion-checkbox>
      <ion-label class="op-body-small">
        <ion-text color="black-80" [innerHtml]="'account.terms' | translate"></ion-text>
      </ion-label>
    </ion-item>
    <app-form-field-error-messages
      *ngIf="signUpForm?.controls?.agreement?.touched && signUpForm?.controls?.agreement?.errors"
      [errors]="signUpForm.controls.agreement.errors"
      [errorMessages]="agreementErrorMessages"
    ></app-form-field-error-messages>
  </ion-list>
  <ion-list class="ion-no-padding mt-2 mb-2">
    <ion-item lines="none" class="w-100 d-flex ion-align-items-center ion-justify-content-between">
      <ion-checkbox slot="start" formControlName="trialSubscription"  [name]="'trialSubscription'"></ion-checkbox>
      <ion-label class="op-body-small">
        <ion-text color="black-80" [innerHtml]="'account.subscription' | translate"></ion-text>
      </ion-label>
    </ion-item>
  </ion-list>

  <app-primary-button class="w-100" expand="block" type="submit" [disabled]="disabledButton">{{ 'account.signup' | translate }}</app-primary-button>
</form>
