import { Component, OnInit , Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FormService } from '../../services/form';
import { SignUpService } from '../../services/sign-up';
import { ConfirmEmailModalComponent } from '../confirm-email-modal/confirm-email-modal.component';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnInit {
  signUpForm: FormGroup;

  disabledButton: boolean = false;

  @Output() switchToLogin = new EventEmitter<void>();

  readonly phoneNumberFormat = PhoneNumberFormat.National;
  readonly selectedCountry: CountryISO = CountryISO.Australia;
  readonly searchCountryField = [SearchCountryField.Iso2, SearchCountryField.Name];

  readonly firstNameErrorMessages = {
    required: 'form.errors.required',
    minlength: 'form.errors.minlength',
    maxlength: 'form.errors.maxlength',
  };
  readonly contactNumberErrorMessages = {
    required: 'form.errors.required',
    validatePhoneNumber: 'form.errors.validatePhoneNumber',
  };
  readonly emailErrorMessages = {
    required: 'form.errors.required',
    email: 'form.errors.email',
  };
  readonly passwordErrorMessages = {
    required: 'form.errors.required',
    uppercase: 'form.errors.uppercase',
    lowercase: 'form.errors.lowercase',
    number: 'form.errors.number',
    pattern: 'form.errors.pattern',
    minlength: 'form.errors.minlength',
    maxlength: 'form.errors.maxlength',
  };
  readonly confirmPasswordErrorMessages = {
    required: 'form.errors.required',
    match: 'form.errors.match',
  };
  readonly agreementErrorMessages = {
    required: 'form.errors.required',
  };

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private signUpService: SignUpService,
    private formService: FormService,
    private deviceDetectorService: DeviceDetectorService, // @Optional() private readonly routerOutlet?: IonRouterOutlet,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  closeModal(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  initializeForm(): void {
    this.signUpForm = this.fb.group(
      {
        firstName: ['', Validators.compose([Validators.required, FormService.CustomValidators.onlyspace, Validators.maxLength(50)])],
        lastName: ['', Validators.compose([Validators.required, FormService.CustomValidators.onlyspace, Validators.maxLength(50)])],
        contactNumber: ['', Validators.required],
        agreement: [false, Validators.requiredTrue],
        email: ['', Validators.compose([Validators.required, FormService.CustomValidators.email])],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
            FormService.CustomValidators.uppercase,
            FormService.CustomValidators.lowercase,
            FormService.CustomValidators.number,
            FormService.CustomValidators.specialCharacters,
          ]),
        ],
        confirmPassword: ['', Validators.compose([Validators.required])],
        trialSubscription:[false]
      },
      {
        validators: [FormService.CustomValidators.checkPasswords],
      },
    );
  }

  onSubmit(): void {
    this.signUpForm.markAllAsTouched();

    if (this.signUpForm.invalid) {
      return;
    }
    this.disabledButton = true;
    const { firstName, lastName, contactNumber, email, password, trialSubscription } = this.signUpForm.getRawValue();

    this.signUpService
      .registerAccount(firstName, lastName, contactNumber.e164Number, email, password, trialSubscription)
      .pipe(
        switchMap(() => this.closeModal()),
        switchMap(() => this.openConfirmCardModal()),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => {
            const validationErrors = this.formService.getValidationErrors(error, 'inputs.');

            if (validationErrors && typeof validationErrors !== 'boolean' && Object.keys(validationErrors)?.length) {
              if(validationErrors!.email!.match('has already been taken')){
                validationErrors.email = "form.errors.unique"
              }
              this.formService.setValidationError(this.signUpForm, validationErrors, 'validation_');
            }
            this.disabledButton = false;

            return of();
          },
        ),
      )
      .subscribe(()=>{
        this.disabledButton = false;
      });
  }

  /* istanbul ignore next */
  private async openConfirmCardModal(): Promise<void> {
    return this.modalController
      .create({
        animated: true,
        component: ConfirmEmailModalComponent,
        canDismiss: true,
        cssClass: this.deviceDetectorService.isDesktop() ? 'op-card-modal op-card-modal--wide' : 'op-mobile-card-modal',
      })
      .then((modalElement: HTMLIonModalElement) => modalElement.present());
  }
}
