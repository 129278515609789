<ion-header class="ion-no-border op-mobile-header">
  <ion-toolbar color="primary" class="ion-color-light ion-no-padding">
    <ion-img
      slot="start"
      role="button"
      routerLink="/"
      class="op-logo"
      alt="opin-logo"
      src="https://dv39hlmgorcie.cloudfront.net/assets/images/opin-logo.webp"
    ></ion-img>
    <ion-buttons slot="end">
      <ion-menu-button menu="main-menu"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-header class="op-header">
  <ion-row class="ion-align-items-center d-flex">
    <div class="op-container d-flex">
      <ion-col size="1" class="ion-no-padding ion-justify-content-start d-flex">
        <ion-img role="button" routerLink="/home" class="op-logo" alt="opin-logo" src="https://dv39hlmgorcie.cloudfront.net/assets/images/opin-logo.webp"></ion-img>
      </ion-col>
      <ion-col size="9" class="ion-no-padding ion-justify-content-center d-flex">
        <ion-buttons class="menu" *ngIf="'common.nav' | translate">
          <ion-button
            class="common"
            color="black-60"
            routerLinkActive="op-active-item"
            [routerLinkActiveOptions]="item.linkActiveOptions"
            [routerLink]="[item.link]"
            *ngFor="let item of menuItems; let last = last"
          >
            <ion-text class="op-body mb-0 fw-600">{{ item.title | translate }}</ion-text>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col
        *ngIf="isAuthenticated; else unauthenticated"
        size="2"
        class="ion-no-padding ion-justify-content-end d-flex"
      >
        <ng-container *ngIf="user$ | async as user">
          <app-authenticated-user [user]="user"></app-authenticated-user>
        </ng-container>
      </ion-col>
      <ng-template #unauthenticated>
        <ion-col size="2" class="ion-no-padding ion-justify-content-end d-flex">
          <ion-buttons>
            <ion-button class="fw-600 common ion-margin-end" fill="clear" color="blue" (click)="openLoginModal()">
              {{'account.login' | translate}}
            </ion-button>
            <app-primary-button classList="fw-600" (click)="openSignUpModal()">{{'account.signup' | translate}}</app-primary-button>
          </ion-buttons>
        </ion-col>
      </ng-template>
    </div>
  </ion-row>
</ion-header>
