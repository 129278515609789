<ion-button
  class="common ion-no-margin"
  fill="solid"
  [type]="type"
  [expand]="expand"
  [color]="disabled ? 'black-20' : color"
  [ngClass]="classList"
  [slot]="slot"
  [size]="size"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</ion-button>
