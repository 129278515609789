import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class LogoutMutation extends Mutation {
  /* istanbul ignore next */
  document = gql`mutation Logout {logout {success,message}}`;
}
