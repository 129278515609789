export enum AuthModalTypes {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  VERIFICATION_CODE = 'VERIFICATION_CODE',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
}

export interface AuthModalHeader {
  logo: boolean;
  header: string;
  subheader: string;
}
