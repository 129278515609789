import { Injectable } from '@angular/core';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { catchError, filter, first, mapTo, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { MutationResult } from 'apollo-angular';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Login } from '@op-types/login.model';
import { User } from '@op-types/user.model';
import { LinkedinService } from '../../services/linkedin';
import { AuthenticationService } from '../../services/authentication';
import { LoginMutation } from '../../graphql/mutations/login.mutation';
import { SocialLoginMutation } from '../../graphql/mutations/social-login.mutation';
import { LogoutMutation } from '../../graphql/mutations/logout.mutation';
import { VerifyUserMutation } from '../../graphql/mutations/verify-user.mutation';
import { SendVerificationCodeMutation } from '../../graphql/mutations/send-verification-code.mutation';

@UntilDestroy()
@Injectable()
export class SignInService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginMutation: LoginMutation,
    private logoutMutation: LogoutMutation,
    private modalController: ModalController,
    private linkedinService: LinkedinService,
    private socialAuthService: SocialAuthService,
    private verifyUserMutation: VerifyUserMutation,
    private socialLoginMutation: SocialLoginMutation,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private sendVerificationCodeMutation: SendVerificationCodeMutation,
  ) {
    this.fetchSocialAuthState();
  }

  /* istanbul ignore next */
  get deviceName(): string {
    return `${this.deviceDetectorService.browser}|${this.deviceDetectorService.os}` ?? 'Unknown';
  }

  closeModal(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  loginWithFacebook(): Promise<SocialUser> {
    return this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithGoogle(): Promise<SocialUser> {
    return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithLinkedIn(): void {
    this.linkedinService.openLinkedInAuthorization();
  }

  fetchAuthTokenForLinkedIn(authorizationCode: string): Observable<any> {
    return this.socialLoginMutation
      .mutate({
        token: authorizationCode,
        provider: 'LINKEDIN',
        deviceName: this.deviceName,
      })
      .pipe(
        first(),
        switchMap((response: MutationResult<Login.Social.MutationResponse>) => {
          /* istanbul ignore next */
          this.authenticationService.setUser(response?.data?.socialLogin?.user ?? null);
          /* istanbul ignore next */
          this.authenticationService.setToken(response?.data?.socialLogin?.token ?? '');
          return this.resumeRoute();
        }),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => of(),
        ),
      );
  }

  // todo: have some problems with coverage for this method
  /* istanbul ignore next */
  fetchSocialAuthState(): void {
    this.socialAuthService.authState
      ?.pipe(
        filter((user: SocialUser) => !!user?.authToken),
        switchMap((user: SocialUser) =>
          this.socialLoginMutation.mutate({
            token: user.authToken,
            provider: user.provider,
            deviceName: this.deviceName,
          }),
        ),
        switchMap(
          /* istanbul ignore next */
          async (response: MutationResult<Login.Social.MutationResponse>) => {
            this.authenticationService.setUser(
              ({ ...response?.data?.socialLogin?.user, isSocialAccount: true } as User.Entity) ?? null,
            );
            this.authenticationService.setToken(response?.data?.socialLogin?.token ?? '');
            return this.resumeRoute();
          },
        ),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => of(),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  signIn(email: string, password: string, remember: boolean): Observable<void> {
    return this.loginMutation.mutate({ email, password, deviceName: this.deviceName, remember, type: 'USER' }).pipe(
      first(),
      switchMap(
        /* istanbul ignore next */
        (response: MutationResult<Login.MutationResponse>) => {
          this.authenticationService.setUser(response?.data?.login?.user ?? null);
          this.authenticationService.setToken(response?.data?.login?.token ?? '');
          return this.resumeRoute();
        },
      ),
      catchError(
        /* istanbul ignore next */
        (error: unknown) => {
          // @ts-ignore
          if (error?.graphQLErrors?.length) {
            // @ts-ignore
            return throwError(error.graphQLErrors[0].message);
          }

          return throwError(error);
        },
      ),
    );
  }

  signOut(): Observable<boolean> {
    return this.logoutMutation.mutate().pipe(
      switchMap(
        /* istanbul ignore next */
        () => this.socialAuthService.signOut(),
      ),
      mapTo(true),
      catchError(
        /* istanbul ignore next */
        () => of(true),
      ),
    );
  }

  resentUserVerificationEmail(): Observable<MutationResult<User.VerificationCode.MutationResponse>> {
    return this.sendVerificationCodeMutation.mutate();
  }

  /* istanbul ignore next */
  verifyUser(token: string): Observable<any> {
    return this.verifyUserMutation.mutate({ token });
  }

  /* istanbul ignore next */

  // if user has redirected from protected route, route them back to that page after successful signin
  private resumeRoute(): Promise<void> {
    const redirectUrl = this.route.snapshot.queryParams.redirect_url;
    if (redirectUrl) {
      this.router.navigate([redirectUrl], { replaceUrl: true });
    }
    return Promise.resolve();
  }
}
