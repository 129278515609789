import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';

import { Password } from '@op-types/forgot-password.model';
import { ForgotPasswordMutation } from '../../graphql/mutations/forgot-password.mutation';
import { ResetPasswordMutation } from '../../graphql/mutations/reset-password.mutation';
import { ValidatePasswordCodeMutation } from '../../graphql/mutations/validate-password-code.mutation';

@Injectable()
export class ForgotPasswordService {
  constructor(
    private resetPasswordMutation: ResetPasswordMutation,
    private forgotPasswordMutation: ForgotPasswordMutation,
    private validatePasswordCodeMutation: ValidatePasswordCodeMutation,
  ) {}

  sendVerificationCode(email: string): Observable<MutationResult<Password.Forgot.MutationResponse>> {
    return this.forgotPasswordMutation.mutate({ email });
  }

  checkVerificationCode(
    email: string,
    code: string,
  ): Observable<MutationResult<Password.ValidateCode.MutationResponse>> {
    return this.validatePasswordCodeMutation.mutate({ email, code });
  }

  reset(email: string, code: string, password: string): Observable<MutationResult<Password.Reset.MutationResponse>> {
    return this.resetPasswordMutation.mutate({ email, code, password });
  }
}
