import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialUser } from '@abacritt/angularx-social-login';

import { AuthModalTypes } from '@op-types/auth.model';
import { SignInService } from '../../services/sign-in';
import { AUTH_MODAL_TYPE_HEADERS } from './auth-card-modal.constant';

@Component({
  selector: 'app-auth-card-modal',
  templateUrl: './auth-card-modal.component.html',
  styleUrls: ['./auth-card-modal.component.scss'],
})
export class AuthCardModalComponent {
  @Input() authModalType: AuthModalTypes;
  @Input() authModalTypeHeader: string = "";

  readonly authModalTypesEnum = AuthModalTypes;
  readonly authModalTypeHeaders = AUTH_MODAL_TYPE_HEADERS;

  forgotPasswordPayload: { email: string; code: string };

  constructor(private signInService: SignInService, private modalController: ModalController) {}

  closeModal(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  switchAuthModalType(payload: AuthModalTypes): void {
    this.authModalType = payload;
  }

  updateForgotPasswordPayload(payload: Object): void {
    this.forgotPasswordPayload = {
      ...this.forgotPasswordPayload,
      ...payload,
    };
  }
}
