import { NgModule } from '@angular/core';

import { ForgotPasswordService } from './forgot-password.service';
import { ResetPasswordMutation } from '../../graphql/mutations/reset-password.mutation';
import { ForgotPasswordMutation } from '../../graphql/mutations/forgot-password.mutation';
import { ValidatePasswordCodeMutation } from '../../graphql/mutations/validate-password-code.mutation';

@NgModule({
  providers: [ForgotPasswordService, ForgotPasswordMutation, ValidatePasswordCodeMutation, ResetPasswordMutation],
})
export class ForgotPasswordModule {}
