import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appTogglePasswordAppearance]',
})
export class TogglePasswordAppearanceDirective implements OnInit, OnDestroy {
  private icon: HTMLIonIconElement;
  private isPasswordVisible = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.setup();
  }

  ngOnDestroy(): void {
    this.el.nativeElement.nextSibling.removeEventListener('click', this.togglePasswordAppearance);
  }

  /* istanbul ignore next */
  togglePasswordAppearance(): void {
    this.isPasswordVisible = !this.isPasswordVisible;

    if (this.isPasswordVisible) {
      this.icon.name = 'eye-outline';
      this.el.nativeElement.setAttribute('type', 'text');
    } else {
      this.icon.name = 'eye-off-outline';
      this.el.nativeElement.setAttribute('type', 'password');
    }
  }

  setup(): void {
    const parent = this.el.nativeElement.parentNode;
    const iconCollection = parent.getElementsByTagName('ion-icon');

    this.icon = iconCollection.item(0);

    this.el.nativeElement.nextSibling.addEventListener('click', this.togglePasswordAppearance.bind(this));
  }
}
