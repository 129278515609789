import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class LinkedinService {
  readonly linkedInURL = environment.linkedInURL;
  readonly clientId = environment.linkedinId;
  readonly scope = 'r_liteprofile%20r_emailaddress%20w_member_social';
  readonly redirectUrl = `${window.location.origin}/home`;

  constructor(private http: HttpClient) {}

  /* istanbul ignore next */
  openLinkedInAuthorization(): void {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUrl}&state=sd76fdt67fst6ft67sf&scope=${this.scope}`;
  }

  fetchAccessToken(authorizationCode: string): Observable<{ access_token: string; expires_in: number }> {
    return this.http.post<{ access_token: string; expires_in: number }>(
      this.linkedInURL,
      {},
      {
        params: {
          grant_type: 'authorization_code',
          code: authorizationCode,
          client_id: this.clientId,
          redirect_uri: this.redirectUrl,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }
}
