import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthModalTypes } from '@op-types/auth.model';
import { Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SignInService } from '../../services/sign-in';
import { FormService } from '../../services/form';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() switchAuthModalType$ = new Subject<AuthModalTypes>();

  readonly forgotPasswordModalType = AuthModalTypes.FORGOT_PASSWORD;

  signInForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private signInService: SignInService,
    private modalController: ModalController,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  closeModal(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  initializeForm(): void {
    this.signInForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, FormService.CustomValidators.email])],
      password: ['', Validators.required],
      remember: [false],
    });
  }

  onSubmit(): void {
    this.signInForm.markAllAsTouched();
    if (this.signInForm.invalid) {
      return;
    }

    const { email, password, remember } = this.signInForm.getRawValue();

    this.signInService
      .signIn(email, password, remember)
      .pipe(
        switchMap(() => this.closeModal()),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => {
            this.signInForm.get('password')?.setErrors({ credentials: 'form.errors.credentials' });

            return throwError(error);
          },
        ),
      )
      .subscribe();
  }
}
