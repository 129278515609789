import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage;

  constructor(private storage: Storage) {}

  async init(): Promise<void> {
    this._storage = await this.storage.create();
  }

  set(key: string, value: any): Promise<any> {
    return this._storage.set(key, value);
  }

  get(key: string): Promise<any> {
    return this._storage.get(key);
  }
  /* istanbul ignore next */
  remove(key: string): Promise<void> {
    return this._storage.remove(key);
  }
}
