import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { User } from '@op-types/user.model';
import { StorageService } from '../storage/storage.service';
import { Location } from '@angular/common'

@Injectable()
export class AuthenticationService {
  token: string | null = null;

  private user$ = new BehaviorSubject<null | User.Entity>(null);

  /* istanbul ignore next */
  get user(): null | User.Entity {
    return this.user$.getValue();
  }

  constructor(public storageService: StorageService, public location: Location) {
  }

  async setToken(token: null | string): Promise<void> {
    this.token = token;
    await this.storageService.set('_token', token);
  }

  getToken(): string | null {
    return this.token;
  }

  /* istanbul ignore next */
  selectUser(): Observable<null | User.Entity> {
    return this.user$.asObservable().pipe(distinctUntilChanged());
  }

  /* istanbul ignore next */
  setUser(payload: null | User.Entity): void {
    this.user$.next(payload);
  }

  isAuthenticated(): boolean {
    return !!this.token;
  }

  async getGraphQLHeaders() {
    const campaign = await this.storageService.get('campaign');
    if (this.token) {
      return {
        headers: {
          'locale': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
          Authorization: `Bearer ${this.token}`,
          'X-PATH': typeof window !== "undefined" ? this.location.path() : '',
          'X-CAMPAIGN': campaign?.toString() ?? ""
        },
      };
    } else if (campaign) {
      return {
        headers: {
          'locale': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
          'X-PATH': typeof window !== "undefined" ? this.location.path() : '',
          'X-CAMPAIGN': campaign?.toString() ?? ""
        },
      };
    }
    return {
      headers: {
        'locale': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
        'X-PATH': typeof window !== "undefined" ? this.location.path() : '',
        'X-CAMPAIGN': ""
      },
    };
  }
}
