<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-padding">
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button class="ion-no-padding ion-no-margin" color="black-60" (click)="closeModal()">
        <ion-icon size="large" name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-align-items-center d-flex">
      <ion-col size="12" class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column">
        <ion-img
          *ngIf="authModalTypeHeaders[authModalType]?.logo"
          class="op-logo mb-5"
          alt="opin-logo"
          src="https://dv39hlmgorcie.cloudfront.net/assets/images/opin-logo.webp"
        ></ion-img>
        <h5 class="op-h5 fw-700 ion-text-center" *ngIf="authModalTypeHeader != '' || authModalTypeHeaders[authModalType].header">
          {{ authModalTypeHeader?.length>0 ? authModalTypeHeader : authModalTypeHeaders[authModalType].header | translate  }}</h5>
      </ion-col>
    </ion-row>
    <ng-container [ngSwitch]="authModalType">
      <ng-container *ngSwitchCase="authModalTypesEnum.SIGN_IN">
        <ion-row class="ion-align-items-center d-flex">
          <ion-col
            size="12"
            class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column"
          >
            <app-login-form (switchAuthModalType$)="switchAuthModalType($event)"></app-login-form>
          </ion-col>
          <ion-col size="12" class="mt-5 ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
            <ion-text class="op-body mt-3 mb-3" color="black-60">{{'account.notHaveAccount' | translate}}</ion-text>
            <ion-button
              class="common ion-no-margin fw-500"
              fill="clear"
              color="blue"
              size="small"
              (click)="switchAuthModalType(authModalTypesEnum.SIGN_UP)"
            >
              <ion-text class="op-body fw-600" color="blue">{{'account.signup' | translate}}</ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
        <ng-container [ngTemplateOutlet]="separatorRow"></ng-container>
        <app-social-login [classList]="'w-100'"></app-social-login>
      </ng-container>
      <ng-container *ngSwitchCase="authModalTypesEnum.SIGN_UP">
        <ion-col size="12" class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
          <ion-text class="op-body-small noSpace" color="black-60">{{'account.haveAccount' | translate}}</ion-text>
          <ion-button
            class="common ion-no-margin linkButton noSpace"
            fill="clear"
            color="blue"
            size="small"
            (click)="switchAuthModalType(authModalTypesEnum.SIGN_IN)"
          >
            <ion-text class="op-body fw-600 noSpace" color="blue">{{'account.login' | translate}}</ion-text>
          </ion-button>

        </ion-col>
        <ion-col size="12" class="mt-2 ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
          <ion-text
            *ngIf="authModalTypeHeaders[authModalType]?.subheader"
            class="op-body ion-text-center"
            color="blue"
          >
            {{ authModalTypeHeaders[authModalType].subheader | translate }}
          </ion-text>
        </ion-col>
        <ion-row class="ion-align-items-center d-flex">
          <ion-col
            size="12"
            class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column"
          >
            <app-sign-up-form (switchToLogin)="switchAuthModalType(authModalTypesEnum.SIGN_IN)"></app-sign-up-form>
          </ion-col>
        </ion-row>
        <ng-container [ngTemplateOutlet]="separatorRow"></ng-container>
        <app-social-login class="w-100"></app-social-login>
      </ng-container>
      <ng-container *ngSwitchCase="authModalTypesEnum.FORGOT_PASSWORD">
        <ion-row class="ion-align-items-center d-flex">
          <ion-col
            size="12"
            class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column"
          >
            <app-forgot-password-form
              (switchAuthModalType$)="switchAuthModalType($event)"
              (email$)="updateForgotPasswordPayload({ email: $event })"
            ></app-forgot-password-form>
          </ion-col>
          <ion-col size="12" class="mt-5 ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
            <ion-text class="op-body mt-3 mb-3" color="black-60">{{'account.havePassword' | translate}}</ion-text>
            <ion-button
              class="common mb-0 ion-no-margin fw-500"
              fill="clear"
              color="blue"
              size="small"
              (click)="switchAuthModalType(authModalTypesEnum.SIGN_IN)"
            >
              <ion-text class="op-body mb-0 fw-600" color="blue">{{'account.login' | translate}}</ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="authModalTypesEnum.VERIFICATION_CODE">
        <ion-row class="ion-align-items-center d-flex">
          <ion-col
            size="12"
            class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column"
          >
            <app-verification-code-form
              [email]="forgotPasswordPayload.email"
              (switchAuthModalType$)="switchAuthModalType($event)"
              (code$)="updateForgotPasswordPayload({ code: $event })"
            ></app-verification-code-form>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="authModalTypesEnum.UPDATE_PASSWORD">
        <ion-row class="ion-align-items-center d-flex">
          <ion-col
            size="12"
            class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column"
          >
            <app-update-password-form
              [email]="forgotPasswordPayload?.email"
              [code]="forgotPasswordPayload?.code"
            ></app-update-password-form>
          </ion-col>
        </ion-row>
      </ng-container>
    </ng-container>
  </ion-grid>
</div>



<ng-template #separatorRow>
  <ion-row class="ion-align-items-center d-flex">
    <ion-col size="12" class="ion-no-padding ion-align-items-center ion-justify-content-center d-flex flex-column">
      <div class="w-100 op-separator">
        <ion-text class="op-body-small d-inline-block w-100 mb-2 mt-2" color="black-60">{{ 'account.or' | translate}}</ion-text>
      </div>
    </ion-col>
  </ion-row>
</ng-template>
