import { Injectable } from '@angular/core';

@Injectable()
export class SocialLinkService {
  openLink(url: string): boolean {
    window.open(url, '_system', 'location=yes');

    return true;
  }
}
