<form class="w-100 op-login-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
  <app-form-field classList="mb-3">
    <ion-label position="stacked">
      {{'account.profileText.email' | translate}}<span appAsterixMark controlName="email" [formGroup]="signInForm"></span>
    </ion-label>
    <ion-input id="email" [name]="'email'" placeholder="example@email.com" type="email" formControlName="email"></ion-input>
    <ion-text
      color="danger"
      class="op-form-field__error"
      *ngIf="signInForm?.controls?.email.touched && signInForm?.controls?.email?.errors?.required"
      formError
    >
      {{ 'form.errors.required' | translate}}
    </ion-text>
    <ion-text
      color="danger"
      class="op-form-field__error"
      *ngIf="signInForm?.controls?.email.touched && signInForm?.controls?.email?.errors?.email"
      formError
    >
      {{ 'form.errors.email' | translate}}
    </ion-text>
  </app-form-field>
  <app-form-field classList="mb-2">
    <ion-label position="stacked">
      {{'account.password' | translate}}<span appAsterixMark controlName="password" [formGroup]="signInForm"></span>
    </ion-label>
    <ion-input placeholder="" [name]="'password'" type="password" formControlName="password" appTogglePasswordAppearance></ion-input>
    <ion-button slot="end" fill="clear" color="black-50">
      <ion-icon slot="icon-only" color="black-50" name="eye-off-outline"></ion-icon>
    </ion-button>
    <ion-text
      color="danger"
      class="op-form-field__error"
      *ngIf="signInForm?.controls?.password.touched && signInForm?.controls?.password?.errors?.required"
      formError
    >
      {{ 'form.errors.required' | translate}}
    </ion-text>
    <ion-text
      color="danger"
      class="op-form-field__error"
      *ngIf="signInForm?.controls?.password.touched && signInForm?.controls?.password?.errors?.credentials"
      formError
    >
      {{ 'form.errors.credentials' | translate }}
    </ion-text>
  </app-form-field>
  <ion-list class="ion-no-padding d-flex ion-align-items-center ion-justify-content-between mb-5">
    <ion-item lines="none">
      <ion-checkbox slot="start" formControlName="remember"></ion-checkbox>
      <ion-label class="op-body mt-0 mb-0">{{'account.rememberMe' | translate}}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-button
        class="op-body fw-600 mb-0 ion-no-margin"
        fill="clear"
        color="blue"
        size="small"
        (click)="switchAuthModalType$.next(forgotPasswordModalType)"
      >
        {{'account.forgotPassword' | translate}}
      </ion-button>
    </ion-item>
  </ion-list>
  <app-primary-button class="w-100" expand="block" size="small" type="submit">{{'account.login' | translate}}</app-primary-button>
</form>
