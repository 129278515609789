export interface HeaderMenu {
  title: String;
  link: String;
  linkActiveOptions: LinkActiveOptions;
}

export interface LinkActiveOptions {
  exact: boolean;
}

export const headerMenudata: HeaderMenu[] = [
  {
    title: 'nav.home-page',
    link: '/home',
    linkActiveOptions: { exact: true },
  },
  {
    title: 'nav.disease-And-Condition-page',
    link: '/diseases-and-conditions',
    linkActiveOptions: { exact: true },
  },
  {
    title: 'nav.healthy-volunteers-page',
    link: '/healthy-volunteers',
    linkActiveOptions: { exact: true },
  },
  {
    title: 'nav.sponsor-page',
    link: '/sponsors',
    linkActiveOptions: { exact: true },
  },
  /*{
    title: 'nav.featured-research-page',
    link: '/featured-research',
    linkActiveOptions: { exact: true },
  },*/
  {
    title: 'nav.resource-page',
    link: '/resources',
    linkActiveOptions: { exact: true },
  },
]
