import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormService {
  /* istanbul ignore next */
  static CustomValidators: { [key: string]: ValidatorFn } = {
    onlyspace: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control?.value?.match(/^(\s+\S+\s*)*(?!\s).*$/)) {
        return { required: true };
      }
      return null;
    },
    uppercase: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control?.value?.match(/[A-Z]/)) {
        return { uppercase: true };
      }

      return null;
    },
    lowercase: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control?.value?.match(/[a-z]/)) {
        return { lowercase: true };
      }

      return null;
    },
    number: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control?.value?.match(/[0-9]/)) {
        return { number: true };
      }

      return null;
    },
    specialCharacters: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        return { pattern: true };
      }

      return null;
    },
    checkPasswords: (group: AbstractControl): ValidationErrors | null => {
      let password = group.get('password')?.value;
      let confirmPasswordControl = group.get('confirmPassword');
      confirmPasswordControl?.setErrors(null);

      if (!password || !confirmPasswordControl?.value) {
        return null;
      }

      if (password !== confirmPasswordControl?.value) {
        confirmPasswordControl.setErrors({ match: true });
      }

      return null;
    },
    email: (control: AbstractControl): ValidationErrors | null => {
      if (control?.value && !control.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        return { email: true };
      }

      return null;
    },
  };

  errors: string[] = [];

  constructor() {}

  /* istanbul ignore next */
  isValidationError(error: any): boolean {
    return this.matchValidationErrorCategory(error, 'validation');
  }

  /* istanbul ignore next */
  setValidationError(formGroup: FormGroup, errors: Record<string, string>, prefix = ''): void {
    Object.keys(errors).forEach((key: string) => {
      formGroup.get(key)?.setErrors({ [`${prefix}${key}`]: `${errors[key]}` });
    });
  }

  /* istanbul ignore next */
  getValidationErrors(error: any, prefix = ''): Record<string, string> | boolean {
    if (this.isValidationError(error)) {
      const graphQLErrors = error.graphQLErrors[0].extensions.validation;

      let result = {};

      for (let graphQLError in graphQLErrors) {
        const errorKey = graphQLError.replace(prefix, '');
        let errorMessage = graphQLErrors[graphQLError][0].replace(prefix, '');

        if (errorMessage[errorMessage.length - 1] === '.') {
          errorMessage = errorMessage.slice(0, -1);
        }

        result = { ...result, [errorKey]: errorMessage };
      }

      return result;
    }

    return false;
  }

  /* istanbul ignore next */
  matchValidationErrorCategory(error: any, category: string): boolean {
    if (this.findErrorExtensionValue(error, 'category') === category) {
      return true;
    }

    return false;
  }

  /* istanbul ignore next */
  findErrorExtensionValue(error: any, field: string): string | undefined {
    if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].extensions.hasOwnProperty(field)) {
      return error.graphQLErrors[0].extensions[field];
    }

    return undefined;
  }

  validate(controls: { [key: string]: AbstractControl }) {
    this.errors = [];
    Object.keys(controls).forEach((key) => {
      const prettyKey = key
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ')
        .replace(/(?:^|\s)\S/g, (l) => l.toUpperCase());
      if (controls[key].errors != null) {
        this.doChecks(controls[key], prettyKey);
      }
    });
    return this.errors;
  }

  doChecks(control: AbstractControl, prettyKey: string) {
    this.checkRequired(control.errors, prettyKey);
    this.checkLength(control.errors, prettyKey);
    this.checkNumber(control.errors, prettyKey);
    this.checkDate(control.errors, prettyKey);
    this.checkPattern(control.errors, prettyKey);
    this.checkStrength(control.errors);
    this.checkEmail(control.errors, prettyKey);
    this.checkOnlySpace(control.errors, prettyKey);
  }

  checkRequired(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.required) {
      this.errors.push(`Please fill out the ${prettyKey}.`);
    }
  }

  checkLength(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.maxlength) {
      this.errors.push(`${prettyKey} cannot have more than ${errors.maxlength.requiredLength} characters.`);
    }
    if (errors?.minlength) {
      this.errors.push(`${prettyKey} needs at least ${errors.minlength.requiredLength} characters.`);
    }
  }

  checkNumber(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.max) {
      this.errors.push(`Please enter a(n) ${prettyKey} smaller or equal to ${errors.max.max}`);
    }
    if (errors?.min) {
      this.errors.push(`Please enter a(n) ${prettyKey} greater or equal to ${errors.min.min}`);
    }
    if (errors?.number) {
      this.errors.push(`Please fill out the ${prettyKey} with a valid number.`);
    }
  }

  checkDate(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.date) {
      this.errors.push(`Please fill out the ${prettyKey} with a valid date.`);
    }
  }

  checkPattern(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.pattern) {
      this.errors.push(`Please fill out the ${prettyKey} with a valid date.`);
    }
  }

  checkStrength(errors: ValidationErrors | null) {
    if (errors?.strong) {
      this.errors.push(
        `Password is not strong enough. Please include capital and lower case letters, numbers and special characters.`,
      );
    }
  }

  checkOnlySpace(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.space) {
      this.errors.push(`Please fill out the ${prettyKey} with a valid text.`);
    }
  }

  checkEmail(errors: ValidationErrors | null, prettyKey: string) {
    if (errors?.email) {
      this.errors.push(`Please enter a valid ${prettyKey}.`);
    }
  }
}
