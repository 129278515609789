import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormField } from '../dynamic-form/dynamic-form.data';

@Directive({
  selector: '[appAsterixMark]',
})
export class AsterixMarkDirective implements OnInit {
  @Input() controlName: string;
  @Input() formGroup: FormGroup;
  /* istanbul ignore next */
  @Input()
  set dynamicField(payload: FormField) {
    this.formField = payload;
  }

  private formField: FormField;

  constructor(private elementRef: ElementRef) {}

  /* istanbul ignore next */
  ngOnInit(): void {
    const isRequired = this.formField?.required || this.formGroup?.controls[this.controlName]?.errors?.required;

    if (isRequired) {
      this.elementRef.nativeElement.innerHTML = '*';
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }
}
