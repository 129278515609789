import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-outline-button',
  templateUrl: './outline-button.component.html',
  styleUrls: ['./outline-button.component.scss'],
})
export class OutlineButtonComponent {
  @Input() slot: string;
  @Input() expand: 'block' | 'full' = 'block';
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() classList: string | string[] | Set<string> | { [c: string]: any };
}
