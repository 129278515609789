import {Injectable, Inject } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    @Inject(DOCUMENT) private dom : any,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }
  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  setRedirect(url: string) {
    this.meta.addTag({'http-equiv': 'refresh', content: "0;URL='" + url + "'"});
  }

  setTitle(title: string) {
    this.titleService.setTitle(title + ' | Opin');
    this.meta.updateTag({property: 'og:title', content: title});
  }

  setKeyword(keyword: string) {
    this.meta.updateTag({name: 'keywords', content: keyword});
  }

  setDesc(desc: string) {
    this.meta.updateTag({name: 'description', content: desc});
    this.meta.updateTag({property: 'og:description', content: desc});
  }

  setUrl(url: string) {
    this.meta.updateTag({property: 'og:url', content: url});
  }

  setMetaFromRouter(title: string = "", description: string = "", keyword: string = "") {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)).subscribe((event) => {
      const customObj = event['trial'];
      if (title) {
        this.setTitle(title);
      } else if (customObj?.metaTitle) {
        this.setTitle(customObj.metaTitle);
      } else if (customObj?.title) {
        this.setTitle(customObj.title);
      } else if (customObj?.name) {
        this.setTitle(customObj.name);
      } else if (event['title']) {
        this.setTitle(event['title']);
      }

      if (description) {
        this.setDesc(description);
      } else if (customObj) {
        if (customObj?.metaKeywords) {
          this.setDesc(customObj.metaDescription);
        } else {
          description = "This ";
          if (customObj.studyType?.length > 0) description += customObj.studyType + ' ';
          description += 'study is looking for people ';
          if (customObj.originalConditions?.keyCondition.length > 0) description += 'with [' + customObj.originalConditions?.keyCondition?.slice(0, customObj.originalConditions?.keyCondition?.length>2 ? 2 : customObj.originalConditions?.keyCondition?.length)?.join(', ') + ']';

          if (customObj.facilities?.length > 0) {
            var locations: string[] = [];
            description += ' in [ ';
            customObj.facilities.forEach((facility: any) => {
              var location = facility.city + ' ' + facility.country;
              if (locations.indexOf(location) === -1 && locations.length<3) {
                locations.push(location);
              }
            });
            description += locations.join(', ') + ' ]';
          }

          description += ' to take part.';
          this.setDesc(description);
        }
      } else if (event['description']) {
        this.setDesc(event['description']);
      }

      if (keyword) {
        this.setKeyword(keyword);
      } else if (customObj?.metaKeywords) {
        this.setKeyword(customObj.metaKeywords);
      } else if (event['keywords']) {
        this.setKeyword(event['keywords']);
      }

      this.setUrl(window?.location?.href);

    });
  }
}
