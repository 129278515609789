import { Injectable } from '@angular/core';
import { Login } from '@op-types/index';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class SocialLoginMutation extends Mutation<Login.Social.MutationResponse, Login.Social.MutationVariables> {
  document = gql`mutation SocialLogin($token: String!, $provider: SocialLoginProvider!, $deviceName: String!) { socialLogin(inputs: { provider: $provider, token: $token, deviceName: $deviceName }) { token, user { id,  fullName,  firstName,  lastName,  email,  contactNumber,  hasVerifiedEmail,  profilePercent, trialSubscription, photoTiny: photo(size: TINY), photoThumb: photo(size: THUMB), company { name }, crmToken { token } }}}`;
}
