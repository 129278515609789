import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CheckAuthButtonComponent } from '../components/check-auth-button/check-auth-button.component';
import { FaqComponent } from '../components/faq/faq.component';
import { FooterComponent } from '../components/footer/footer.component';
import { FormFieldComponent } from '../components/form-field/form-field.component';
import { HeaderComponent } from '../components/header/header.component';
import { ImageContentComponent } from '../components/image-content/image-content.component';
import { InfoBlockComponent } from '../components/info-block/info-block.component';
import { MenuComponent } from '../components/menu/menu.component';
import { OutlineButtonComponent } from '../components/outline-button/outline-button.component';
import { PrimaryButtonComponent } from '../components/primary-button/primary-button.component';
import { RadioButtonGroupComponent } from '../components/radio-button-group/radio-button-group.component';
import { DirectivesModule } from '../directives/directives.module';
import { AsterixMarkDirective } from './asterix-mark/asterix-mark.directive';
import { AuthCardModalComponent } from './auth-card-modal/auth-card-modal.component';
import { AuthenticatedUserComponent } from './authenticated-user/authenticated-user.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ConfirmEmailModalComponent } from './confirm-email-modal/confirm-email-modal.component';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { CheckboxInputComponent } from './form-components/checkbox-input/checkbox-input.component';
import { DateInputComponent } from './form-components/date-input/date-input.component';
import { PhoneInputComponent } from './form-components/phone-input/phone-input.component';
import { RadioInputComponent } from './form-components/radio-input/radio-input.component';
import { SegmentInputComponent } from './form-components/segment-input/segment-input.component';
import { SelectInputComponent } from './form-components/select-input/select-input.component';
import { TextInputComponent } from './form-components/text-input/text-input.component';
import { TextareaInputComponent } from './form-components/textarea-input/textarea-input.component';
import { FormFieldErrorMessagesComponent } from './form-field-error-messages/form-field-error-messages.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { PrivacyAgreementItemComponent } from './privacy-agreement-item/privacy-agreement-item.component';
import { SearchConditionComponent } from './search-condition/search-condition.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchLocationComponent } from './search-location/search-location.component';
import { SearchFormComponent } from './search-form/search-form.component';

import { ResourceSearchBarComponent } from './resource-search-bar/resource-search-bar.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleCardComponent } from './article-card/article-card.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { CustomSectionComponent } from './custom-section/custom-section.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { TogglePasswordAppearanceDirective } from './toggle-password-appearance/toggle-password-appearance.directive';
import { ToggleSaveTrialButtonComponent } from './toggle-save-trial-button/toggle-save-trial-button.component';
import { UpdatePasswordFormComponent } from './update-password-form/update-password-form.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { VerificationCodeFormComponent } from './verification-code-form/verification-code-form.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ImageContentComponent,
    MenuComponent,
    CheckAuthButtonComponent,
    OutlineButtonComponent,
    PrimaryButtonComponent,
    FaqComponent,
    SearchConditionComponent,
    SearchBarComponent,
    SearchLocationComponent,
    SearchFormComponent,
    CustomSectionComponent,
    FormFieldComponent,
    RadioButtonGroupComponent,
    InfoBlockComponent,
    AuthCardModalComponent,
    LoginFormComponent,
    ToggleSaveTrialButtonComponent,
    SignUpFormComponent,
    TogglePasswordAppearanceDirective,
    ForgotPasswordFormComponent,
    VerificationCodeFormComponent,
    UpdatePasswordFormComponent,
    PrivacyAgreementItemComponent,
    DynamicFormComponent,
    TextInputComponent,
    PhoneInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    SegmentInputComponent,
    SelectInputComponent,
    DateInputComponent,
    DeleteAccountModalComponent,
    ChangePasswordModalComponent,
    AuthenticatedUserComponent,
    TextareaInputComponent,
    FormFieldErrorMessagesComponent,
    UserMenuComponent,
    ConfirmEmailModalComponent,
    NoResultsComponent,
    AsterixMarkDirective,
    SocialLoginComponent,
    ResourceSearchBarComponent,
    ArticleListComponent,
    ArticleCardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    NgOtpInputModule,
    DirectivesModule,
    NgxIntlTelInputModule,
    TranslateModule,
    NgxPaginationModule
  ],
  entryComponents: [AuthCardModalComponent],
  exports: [
    HeaderComponent,
    FooterComponent,
    ImageContentComponent,
    MenuComponent,
    CheckAuthButtonComponent,
    OutlineButtonComponent,
    PrimaryButtonComponent,
    FaqComponent,
    SearchConditionComponent,
    SearchBarComponent,
    SearchLocationComponent,
    SearchFormComponent,
    CustomSectionComponent,
    FormFieldComponent,
    RadioButtonGroupComponent,
    InfoBlockComponent,
    AuthCardModalComponent,
    LoginFormComponent,
    SignUpFormComponent,
    ToggleSaveTrialButtonComponent,
    ForgotPasswordFormComponent,
    VerificationCodeFormComponent,
    UpdatePasswordFormComponent,
    PrivacyAgreementItemComponent,
    DynamicFormComponent,
    TextInputComponent,
    PhoneInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    SegmentInputComponent,
    SelectInputComponent,
    DateInputComponent,
    DeleteAccountModalComponent,
    ChangePasswordModalComponent,
    AuthenticatedUserComponent,
    TextareaInputComponent,
    UserMenuComponent,
    FormFieldErrorMessagesComponent,
    ConfirmEmailModalComponent,
    NoResultsComponent,
    AsterixMarkDirective,
    SocialLoginComponent,
    ResourceSearchBarComponent,
    ArticleListComponent,
    ArticleCardComponent
  ],
})
export class ComponentsModule {}
