<ion-menu type="overlay" contentId="main-content" menuId="main-menu" side="start">
  <ion-header class="op-mobile-header ion-no-padding ion-no-border">
    <ion-toolbar color="primary" class="ion-color-light ion-no-padding">
      <ion-img
        slot="start"
        role="button"
        routerLink="/"
        class="op-logo"
        alt="opin-logo"
        src="https://dv39hlmgorcie.cloudfront.net/assets/images/opin-logo.webp"
      ></ion-img>
      <ion-buttons slot="end">
        <ion-menu-toggle>
          <ion-button>
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content scrollY="false">
    <ion-list class="ion-no-padding" *ngIf="'common.nav' | translate">
      <ion-menu-toggle auto-hide="false" *ngFor="let item of menuItems; let last = last">
        <ion-item
          class="ion-no-padding mobile-menus"
          detail="false"
          routerDirection="root"
          [routerLink]="[item.link]"
          [lines]="last ? 'full' : 'none'"
          routerLinkActive="op-active-item"
          [routerLinkActiveOptions]="item.linkActiveOptions"
        >
          <ion-text class="ion-padding-start ion-padding-end mobile-menu">{{ item.title | translate }}</ion-text>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
    <ion-row *ngIf="isAuthenticated; else unauthenticated" class="ion-no-padding ion-justify-content-end d-flex">
      <ng-container *ngIf="user$ | async as user">
        <ion-col size="12" class="ion-no-padding mb-3">
          <ion-accordion-group>
            <ion-accordion value="links">
              <ion-item slot="header">
                <ion-avatar role="button" id="side-button">
                  <img [src]="user.photoTiny" [alt]="user.fullName" [title]="user.fullName" />
                </ion-avatar>
                <ion-text class="mobile-menu">{{ user.fullName }}</ion-text>
              </ion-item>
              <ion-list slot="content">
                <ion-item
                  [button]="true"
                  [detail]="false"
                  lines="none"
                  routerLink="/account/profile"
                  (click)="closeMenu()"
                >
                  <ion-text class="ion-no-margin mobile-menu">{{ 'account.menu.myProfile' | translate }}</ion-text>
                  <ion-icon size="small" name="person-outline" slot="end"></ion-icon>
                </ion-item>
                <ion-item
                  [button]="true"
                  [detail]="false"
                  lines="none"
                  routerLink="/account/previous-entries/research-volunteers/edit"
                  (click)="closeMenu()"
                >
                  <ion-text class="ion-no-margin mobile-menu">{{ 'account.menu.healthProfile' | translate }}</ion-text>
                  <ion-icon size="small" name="document-lock-outline" slot="end"></ion-icon>
                </ion-item>
                <ion-item
                  [button]="true"
                  [detail]="false"
                  lines="none"
                  routerLink="/account/saved-trials"
                  (click)="closeMenu()"
                  *ngIf="'common.nav' | translate"
                >
                  <ion-text class="mobile-menu">{{ 'account.menu.saveStudies' | translate }}</ion-text>
                  <ion-icon size="small" name="bookmark-outline" slot="end"></ion-icon>
                </ion-item>

                <!--<ion-item
                  [button]="true"
                  [detail]="false"
                  lines="none"
                  routerLink="/account/connection-request"
                  (click)="closeMenu()"
                  *ngIf="'common.nav' | translate"
                >
                  <ion-text class="mobile-menu">{{ 'account.menu.connectionRequests' | translate }}</ion-text>
                  <ion-icon size="small" name="link-outline" slot="end"></ion-icon>
                </ion-item>-->
                <ion-item
                  [button]="true"
                  [detail]="false"
                  lines="none"
                  routerLink="/account/previous-entries"
                  (click)="closeMenu()"
                  *ngIf="'common.nav' | translate"
                >
                  <ion-text class="mobile-menu">{{ 'account.menu.previousEntries' | translate }}</ion-text>
                  <ion-icon size="small" name="newspaper-outline" slot="end"></ion-icon>
                </ion-item>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-col>
        <ion-col size="12" class="ion-padding mb-3">
          <app-outline-button class="op-logout" (click)="logout()">{{ 'account.logout' | translate }}</app-outline-button>
        </ion-col>
      </ng-container>
    </ion-row>
    <ng-template #unauthenticated>
      <ion-row class="ion-padding">
        <ion-col size="12" class="ion-no-padding mb-3">
          <app-primary-button (click)="openSignUpCard()">{{'account.signup' | translate}}</app-primary-button>
        </ion-col>
        <ion-col size="12" class="ion-no-padding mb-3">
          <app-outline-button (click)="openLoginCard()">{{'account.login' | translate}}</app-outline-button>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-content>
</ion-menu>
