<ion-item class="op-form-field-wrapper" [ngClass]="classList">
  <div class="op-label-wrapper">
    <ng-content select="ion-label"></ng-content>
  </div>
  <ion-item class="op-form-field">
    <ng-content select="ion-input"></ng-content>
    <ng-content></ng-content>
  </ion-item>
  <ng-content select="[formError]"></ng-content>
  <app-form-field-error-messages
    *ngIf="control?.touched && control?.errors"
    [errors]="control.errors"
    [errorMessages]="errorMessages"
  ></app-form-field-error-messages>
  <ng-container *ngIf="defaultNote?.length>0">
    <ion-text color="medium" class="op-body-small">
      {{ defaultNote }}<br />
    </ion-text>
  </ng-container>
</ion-item>
