import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import * as Sentry from '@sentry/angular';
//import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_VERSION } from './version';

/*Sentry.init({
  dsn: environment.sentry_dsn,
  release: APP_VERSION,
  environment: environment.name,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.apiURL],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.01,
});*/

if (environment.production) {
  enableProdMode();
  window.console.warn = () => { };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
