import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { Password } from '@op-types/index';

@Injectable()
export class ValidatePasswordCodeMutation extends Mutation<
  Password.ValidateCode.MutationResponse,
  Password.ValidateCode.MutationVariables
> {
  document = gql` mutation ValidatePasswordCode($email: String!, $code: String!) { validatePasswordCode(inputs: { email: $email, code: $code }) {success, message}}`;
}
