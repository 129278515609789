import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { Login } from '@op-types/index';

@Injectable({
  providedIn: 'root',
})
export class LoginMutation extends Mutation<Login.MutationResponse, Login.MutationVariables> {
  /* istanbul ignore next */
  document = gql`mutation Login($email: String!, $password: String!, $deviceName: String!, $remember: Boolean!, $type: LoginType!) {login(inputs: { email: $email, password: $password, remember: $remember, deviceName: $deviceName, type: $type }) {token,user {id,fullName,firstName,lastName,email,contactNumber,hasVerifiedEmail,profilePercent,trialSubscription,trialPreference { dob,age,gender,travelDistance,medicalConditions,location }, photoTiny: photo(size: TINY), photoThumb: photo(size: THUMB), company { name }, crmToken { token }}}}`;
}
