import {Injectable, HostListener} from '@angular/core';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {NavigationEnd, NavigationStart, Router, ActivatedRoute} from '@angular/router';
import {StorageService} from '../storage/storage.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../authentication';
import {environment} from '../../../environments/environment';
import {Pageview} from '../../../types';


@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private scrollSubject = new Subject<CustomEvent>();
  private clickSubject = new Subject<Event>();
  private unloadSubject = new Subject<Event>();
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  private storageKey = 'deviceID'

  pageView: any;
  scrollTimeout: any;
  scrollLeft: number = 0;
  scrollTop: number = 0;
  deviceID: string = "";
  sessionID: string = "";
  refererUrl: any;


  readonly rootUrl = `${environment.apiURL}/api/tracking`;
  readonly endPoints = {
    getDeviceID: `${this.rootUrl}/getDeviceID`,
    save: `${this.rootUrl}/save`,
  };

  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private storage: StorageService,
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.getSessionId();
    this.initalData();
  }

  getSessionId() {
    var sessionKey ='opin-session-id';
    this.sessionID = sessionStorage.getItem(sessionKey) ?? '';
    if (this.sessionID.length == 0){
      this.sessionID = 'OS.' + (new Date()).valueOf().toString()+'.'+Math.random().toString().substring(2, 15);
      sessionStorage.setItem(sessionKey,this.sessionID);
    }
  }

  saveData() {
    var encryptedData = btoa(unescape(encodeURIComponent((JSON.stringify(this.pageView).replace(/\\n/g, '')))));
    this.http.post(this.endPoints['save'], {data: encryptedData}, {headers: this.getHeaders}).pipe().subscribe((response) => {
      if (this.pageView.pageID == 0) {
        // @ts-ignore
        this.pageView.pageID = response?.id;
      }
    });
  }

  get getHeaders() {
    const token = this.authService.getToken();
    return token === null
      ? new HttpHeaders({'DeviceID': this.deviceID,'SessionID':this.sessionID})
      : new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'DeviceID': this.deviceID,
        'SessionID':this.sessionID
      });
  }

  async initalData() {
    this.deviceID = localStorage.getItem(this.storageKey)??'';

    if (this.deviceID.length == 0) {
      const response = await this.http.post(
       this.endPoints['getDeviceID'],
       {
         resolution: window.screen.width + ' * ' + window.screen.height,
         language: window.navigator.language,
         cookieEnabled: window.navigator.cookieEnabled
       },
       {headers: this.getHeaders}).toPromise();
       // @ts-ignore
       this.deviceID = response?.id;
       if (this.deviceID) {
         localStorage.setItem(this.storageKey, this.deviceID);
       }
    }

    this.pageView = {
      pageID:0,
      deviceID: this.deviceID,
      url: this.router.url,
      ref: this.previousUrl.value,
      loadAt: new Date().getTime(),
      leftAt: 0,
      events: [{
        timestamp: new Date().getTime(),
        event: 'Load',
        target: '',
        value: '',
        tagType: '',
        tagName: '',
        x: this.scrollLeft,
        y: this.scrollTop,
        detail: this.router.url
      }]
    };

    if (this.router.url != '/') {
      this.saveData();
    }

  }

  emitNewPage(newUrl:string) {
    this.pageView.leftAt = new Date().getTime();
    this.pageView.events.push({
      timestamp: new Date().getTime(),
      event: 'Next Page',
      target: '',
      value: '',
      tagType: '',
      tagName: '',
      x: this.scrollLeft,
      y: this.scrollTop,
      detail: newUrl
    });
    this.saveData();
    this.initalData();
  }

  addCustomEvent(event: any) {
    this.pageView.events.push(event);
  }

  emitFouseOut(event: any) {
    this.pageView.events.push({
      timestamp: new Date().getTime(),
      event: 'Focus Out',
      target: this.parseEventTarget(event),
      value: this.parseEventValue(event),
      tagType: event.target.type,
      tagName: event.target.tagName,
      x: this.scrollLeft + event.x,
      y: this.scrollTop + event.y,
      detail: ''
    });
  }

  emitFouse(event: any) {
    this.pageView.events.push({
      timestamp: new Date().getTime(),
      event: 'Focus',
      target: this.parseEventTarget(event),
      value: this.parseEventValue(event),
      tagType: event.target.type,
      tagName: event.target.tagName,
      x: this.scrollLeft + event.x,
      y: this.scrollTop + event.y,
      detail: ''
    });
  }

  emitUnload(event: any) {
    this.pageView.leftAt = new Date().getTime();
    this.pageView.events.push({
      timestamp: new Date().getTime(),
      event: event.type,
      target: '',
      value: '',
      tagType: '',
      tagName: '',
      x: this.scrollLeft,
      y: this.scrollTop,
      detail: ''
    });
    this.saveData();
  }

  private parseEventTarget(event: any){
    var target: string = "";
    if (event.target.id?.length > 0) {
      target = event.target.id;
    } else if (event?.target.name?.length > 0) {
      target = event.target.name;
    } else if (event?.target.role?.length > 0) {
      target = event.target.role;
    } else {
      target = event.target.innerText;
    }
    return target;
  }

  private parseEventValue(event: any){
    var value: string = "";
    let targetType = event.target.type ? event.target.type : event.target.role;

    switch (targetType?.toLowerCase()) {
      case "textarea":
        value = event.target.innerHTML;
        break;
      case 'button':
      case 'app-primary-button':
        value = event.target.innerText;
        break;
      case 'checkbox':
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }

    return value;
  }

  emitClick(event: any) {
    this.pageView.events.push({
      timestamp: new Date().getTime(),
      event: 'Click',
      target: this.parseEventTarget(event),
      value: this.parseEventValue(event),
      tagType: event.target.type,
      tagName: event.target.tagName,
      x: this.scrollLeft + event.x,
      y: this.scrollTop + event.y,
      detail: ''
    });
  }

  mouseMoveTimeOut: any;
  emitMouseMovement(event:any) {
    clearTimeout(this.mouseMoveTimeOut);
    this.mouseMoveTimeOut = setTimeout(() => {
      this.pageView?.events?.push({
        timestamp: new Date().getTime(),
        event: 'MouseMove',
        target: '',
        value: '',
        tagType: '',
        tagName: '',
        x: Math.round(this.scrollLeft + event.screenX),
        y: Math.round(this.scrollTop + event.screenY),
        detail: "Screen Position:" + Math.round(event.screenX) + ', ' + Math.round(event.screenY)
      });
    }, 250);
  }

  emitScroll(event: CustomEvent) {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.scrollLeft = Math.round(event.detail.scrollLeft);
      this.scrollTop = Math.round(event.detail.scrollTop);
      this.pageView?.events?.push({
        timestamp: new Date().getTime(),
        event: 'Scroll',
        target: '',
        value: '',
        tagType: '',
        tagName: '',
        x: this.scrollLeft,
        y:this.scrollTop,
        detail: 'Left:' + this.scrollLeft + ', Top:' + this.scrollTop
      });
    }, 250);
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
}
