import { APP_INITIALIZER, ErrorHandler, NgModule, Provider, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
/*import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';*/
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

//import * as Sentry from '@sentry/angular';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';

import { GraphQLModule } from './graphql.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormModule } from './services/form';
import { AlertModule, AuthenticationModule, SocialLinkModule } from './services';
import { ComponentsModule } from './components/components.module';
import { SignInModule } from './services/sign-in';
import { LinkedinModule } from './services/linkedin';
import { SignUpModule } from './services/sign-up';
import { StorageService } from './services/storage/storage.service';
import { AuthenticationService } from './services/authentication';
import { ForgotPasswordModule } from './services/forgot-password';
import { UserQuery } from './graphql/queries/user.query';
import { initializerFactory } from './utils/factories/initializer-factory';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v="+(new Date()).getTime());
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return 'some value';
  }
}

const isDevModeOn = !environment.production;

/*let SentryProviders: Provider[] = [];

if (!isDevModeOn) {
  SentryProviders = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}*/

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: 'Opyl',
      driverOrder: [Drivers.LocalStorage],
    }),
    AlertModule,
    AuthenticationModule,
    LinkedinModule,
    SignInModule,
    SignUpModule,
    FormModule,
    ForgotPasswordModule,
    SocialLinkModule,
    ComponentsModule,
    GraphQLModule,
    HttpClientModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      //missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    //...SentryProviders,
    //StatusBar,
    //SplashScreen,
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      multi: true,
      deps: [UserQuery, StorageService, AuthenticationService],
    },
    {provide: Window, useValue: window},
    {provide: Document, useValue: document},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
