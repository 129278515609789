<ion-avatar role="button" id="trigger-button" *ngIf="user" (click)="togglePopover($event)">
  <img [src]="user.photoTiny || 'assets/images/avatar.svg'" [alt]="user.fullName" [title]="user.fullName" />
  <ion-popover [isOpen]="showPopup" #popover (didDismiss)="closePopover()" [showBackdrop]="false">
    <ng-template>
      <ion-list>
        <ion-item [button]="true" [detail]="false" lines="none" routerLink="/account/profile" (click)="closePopover()">
          <ion-label>{{ 'account.menu.myProfile' | translate }}</ion-label>
          <ion-icon name="person-outline" slot="start"></ion-icon>
        </ion-item>
        <ion-item
          [button]="true"
          [detail]="false"
          lines="none"
          routerLink="/account/previous-entries/research-volunteers/edit"
          (click)="closePopover()"
          *ngIf="'common.account' | translate"
        >
          <ion-label>{{ 'account.menu.healthProfile' | translate }}</ion-label>
          <ion-icon name="document-lock-outline" slot="start"></ion-icon>
        </ion-item>
        <ion-item
          [button]="true"
          [detail]="false"
          lines="none"
          routerLink="/account/saved-trials"
          (click)="closePopover()"
          *ngIf="'common.account' | translate"
        >
          <ion-label>{{ 'account.menu.saveStudies' | translate }}</ion-label>
          <ion-icon name="bookmark-outline" slot="start"></ion-icon>
        </ion-item>
<!--        <ion-item
          [button]="true"
          [detail]="false"
          lines="none"
          routerLink="/account/connection-request"
          (click)="closePopover()"
          *ngIf="'common.account' | translate"
        >
          <ion-label>{{ 'account.menu.connectionRequests' | translate }}</ion-label>
          <ion-icon name="link-outline" slot="start"></ion-icon>
        </ion-item>-->
        <ion-item
          [button]="true"
          [detail]="false"
          lines="none"
          routerLink="/account/previous-entries"
          (click)="closePopover()"
          *ngIf="'common.account' | translate"
        >
          <ion-label>{{ 'account.menu.previousEntries' | translate }}</ion-label>
          <ion-icon name="newspaper-outline" slot="start"></ion-icon>
        </ion-item>
        <ion-item-divider></ion-item-divider>
        <ion-item [button]="true" [detail]="false" lines="none" (click)="logout()">
          <ion-label>{{ 'account.logout' | translate }}</ion-label>
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-popover>
</ion-avatar>
