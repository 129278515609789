import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MutationResult } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';

import { User } from '@op-types/user.model';
import { SignInService } from '../../services/sign-in';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-confirm-email-modal',
  templateUrl: './confirm-email-modal.component.html',
  styleUrls: ['./confirm-email-modal.component.scss'],
})
export class ConfirmEmailModalComponent {
  constructor(
    private toastService: ToastService,
    private signInService: SignInService,
    private modalController: ModalController,
  ) {}

  closeModal(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  resentUserVerificationEmail(): void {
    this.signInService
      .resentUserVerificationEmail()
      .pipe(
        first(),
        tap((result: MutationResult<User.VerificationCode.MutationResponse>) =>
          this.toastService.presentToast(result.data!.sendVerificationCode.message),
        ),
        catchError(
          /* istanbul ignore next */
          () => of(),
        ),
        finalize(() => this.closeModal()),
      )
      .subscribe();
  }
}
