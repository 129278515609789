import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Password } from '@op-types/forgot-password.model';
import { MutationResult } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';

import { ForgotPasswordService } from '../../services/forgot-password';
import { FormService } from '../../services/form';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-update-password-form',
  templateUrl: './update-password-form.component.html',
  styleUrls: ['./update-password-form.component.scss'],
})
export class UpdatePasswordFormComponent implements OnInit {
  @Input() code: string;
  @Input() email: string;

  updatePasswordForm: FormGroup;

  readonly passwordErrorMessages = {
    required: 'form.errors.required',
    uppercase: 'form.errors.uppercase',
    lowercase: 'form.errors.lowercase',
    number: 'form.errors.number',
    pattern: 'form.errors.pattern',
    minlength: 'form.errors.minlength',
    maxlength: 'form.errors.maxlength',
  };
  readonly confirmPasswordErrorMessages = {
    required: 'form.errors.required',
    match: 'form.errors.match',
  };

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private modalController: ModalController,
    private forgotPasswordService: ForgotPasswordService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.updatePasswordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
            FormService.CustomValidators.uppercase,
            FormService.CustomValidators.lowercase,
            FormService.CustomValidators.number,
            FormService.CustomValidators.specialCharacters,
          ]),
        ],
        confirmPassword: ['', Validators.compose([Validators.required])],
      },
      {
        validators: [FormService.CustomValidators.checkPasswords],
      },
    );
  }

  onSubmit(): void {
    if (this.updatePasswordForm.invalid) {
      return;
    }

    const { password } = this.updatePasswordForm.getRawValue();

    this.forgotPasswordService
      .reset(this.email, this.code, password)
      .pipe(
        first(),
        tap((result: MutationResult<Password.Reset.MutationResponse>) => {
          /* istanbul ignore next */
          if (result.data?.resetPassword?.message) {
            this.toastService.presentToast(result.data.resetPassword.message);
          }
        }),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => of(),
        ),
        finalize(() => this.modalController.dismiss()),
      )
      .subscribe();
  }
}
