import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '@op-types/user.model';
import { of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication';
import { SignInService } from '../../services/sign-in';

@UntilDestroy()
@Component({
  selector: 'app-authenticated-user',
  templateUrl: './authenticated-user.component.html',
  styleUrls: ['./authenticated-user.component.scss'],
})
export class AuthenticatedUserComponent {
  @ViewChild('popover') popover: any;

  @Input() user: User.Entity;

  showPopup = false;

  constructor(
    private router: Router,
    private signInService: SignInService,
    private authenticationService: AuthenticationService,
  ) {
  }

  logout(): void {
    this.closePopover();
    this.signInService
      .signOut()
      .pipe(
        switchMap(() => this.authenticationService.setToken(null)),
        tap(() => this.authenticationService.setUser(null)),
        catchError(
          /* istanbul ignore next */
          (error: unknown) => of(),
        ),
        finalize(() => {
          let landingPage = localStorage.getItem('landingPage') ?? this.router.url;
          if (landingPage == this.router.url && this.router.url.includes('/account')) {
            landingPage = '/home';
          }
          this.router.navigateByUrl(landingPage);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  closePopover(): void {
    this.showPopup = false;
  }

  togglePopover(e: Event): void {
    this.popover.event = e;
    this.showPopup = !this.showPopup;
  }
}
