import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home-page.module').then((m) => m.HomePageModule),
    data: {
      title: 'Find and participate in medical research studies',
      keywords:'',
      description:'Opin is a global platform designed to help people find and take part in health and medical research, giving them more control over their healthcare journey.',
    }
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then((m) => m.QuestionnairePageModule),
    data: {
      title: 'Questionnaire | Find and participate in medical research studies',
      keywords:'',
      description:'Opin is a global platform designed to help people find and take part in health and medical research, giving them more control over their healthcare journey.',
    }
  },
  { path: 'featured-clinical-trial/:id', redirectTo: 'featured-research/:id', pathMatch: 'full'},
  { path: 'user/trial/search', redirectTo: 'trials', pathMatch: 'full'},
  { path: 'user/trial/:id', redirectTo: 'trials/:id', pathMatch: 'full'},
  { path: 'page/healthy-volunteers', redirectTo: 'healthy-volunteers', pathMatch: 'full'},
  {
    path: 'trials',
    loadChildren: () => import('./pages/trials/trials.module').then((m) => m.TrialsPageModule),
    data: {
      title: 'search medical research studies around the world.',
      keywords:'',
      description:'Opin is a global platform designed to help people find and take part in health and medical research, giving them more control over their healthcare journey.',
    }
  },
  {
    path: 'healthy-volunteers',
    loadChildren: () =>
      import('./pages/healthy-volunteers/healthy-volunteers.module').then((m) => m.HealthyVolunteersPageModule),
    data: {
      title: 'Participate In Medical Research | Research Volunteer Opportunities',
      keywords:'',
      description:'Anyone can take part in research, whether you are living with a health condition or not. We can all contribute to our own healthcare journey, and help improve those of others.',
    }
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
    data: {
      title: 'About Opin | Our Team and Our Goals',
      keywords:'',
      description:'Our passion is making people more active partners in healthcare. Together we aim to make research more accessible, more efficient, more inclusive and more connected.',
    }
  },
  {
    path: 'sponsors',
    loadChildren: () => import('./pages/sponsors/sponsors.module').then((m) => m.SponsorsPageModule),
    data: {
      title: 'Connect to a world of motivated research volunteers',
      keywords:'',
      description:'Access Opin’s smart recruitment platform that leverages social media and search engine optimization to link participants directly to your research opportunity.',
    }
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyPageModule),
    data: {
      title: 'Privacy Policy | How Opin handle and protect the privacy of personal information',
      keywords:'',
      description:'This Privacy Policy applies to all services offered by Opin Limited and outlines how we handle and protect the privacy of personal information that you may provide to us.',
    }
  },
  {
    path: 'diseases-and-conditions',
    loadChildren: () => import('./pages/diseases-and-conditions/diseases-and-conditions.module').then( m => m.DiseasesAndConditionsPageModule),
    data: {
      title: 'Research by Disease and Conditions',
      keywords:'',
      description: 'Find health and medical research studies, including clinical trials, by disease and conditions areas.'
    }
  },
  {
    path: 'featured-research',
    loadChildren: () => import('./pages/featured-research/featured-research.module').then( m => m.FeaturedResearchPageModule),
    data: {
      title: 'Featured Research Opportunities',
      keywords:'',
      description: 'View health and medical research studies, including clinical trials, looking for people to take part.'
    }
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./pages/ai-disclaimer/ai-disclaimer.module').then( m => m.AiDisclaimerPageModule),
    data: {
      title: 'Disclaimer on the use of GPT to simplify information',
      keywords:'',
      description: 'Read the disclaimer by Opin on the use of AI to simplify hard-to-read medical and scientific terms.'
    }
  },
  {
    path: 'social-media-policy',
    loadChildren: () => import('./pages/social-media-policy/social-media-policy.module').then( m => m.SocialMediaPolicyPageModule),
    data: {
      title: 'Opin Social Media Policy',
      keywords:'',
      description: 'View the social media policy and community guidelines of Opin, which illustrate how our team manages our social media pages and communities'
    }
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/resources/resources.module').then( m => m.ResourcesPageModule),
    data: {
      title: 'Resources about Healthy research',
      keywords:'',
      description: 'The latest News and Common knowladge In Healthy research'
    }
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
