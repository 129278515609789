import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'stage',
  lang: 'en',
  production: true,
  sentry_dsn: '',
  apiURL: 'https://www.staging.opin.ai',
  // We are using the same prod app for social login for staging and production
  facebookId: '761578588351387',
  googleId: '57086091835-lm3q457dsldt30jnb95rr83tmsuq5s25.apps.googleusercontent.com',
  linkedinId: '77f52u02afmoag',
  linkedInURL: 'https://www.linkedin.com/oauth/v2/accessToken',
};
