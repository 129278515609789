import { ApolloQueryResult } from '@apollo/client/core';

import { User } from '@op-types/user.model';
import { UserQuery } from '../../graphql/queries/user.query';
import { AuthenticationService } from '../../services/authentication';
import { StorageService } from '../../services/storage/storage.service';

export const initializerFactory = (
  userQuery: UserQuery,
  storageService: StorageService,
  authenticationService: AuthenticationService,
) => {
  return async () => {
    await storageService.init();

    const token = await storageService.get('_token');

    await authenticationService.setToken(token);
    if (token) {
      try {
        const payload: ApolloQueryResult<User.QueryResponse> = await userQuery.watch().result();
        authenticationService.setUser(payload.data.user);
      } catch (err) {
        await storageService.remove('_token');
        await authenticationService.setToken(null);
        authenticationService.setUser(null);
      }
    }
  };
};
