import { Injectable } from '@angular/core';
import { User } from '@op-types/index';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class SendVerificationCodeMutation extends Mutation<User.VerificationCode.MutationResponse> {
  /* istanbul ignore next */
  document = gql` mutation SendVerificationCode { sendVerificationCode { success, message } } `;
}
