import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOtpInputComponent } from 'ng-otp-input';
import { MutationResult } from 'apollo-angular';
import { of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, debounceTime, distinctUntilChanged, filter, first, switchMap, tap } from 'rxjs/operators';

import { AuthModalTypes } from '@op-types/auth.model';
import { Password } from '@op-types/forgot-password.model';
import { ForgotPasswordService } from '../../services/forgot-password';
import { ToastService } from '../../services/toast/toast.service';

@UntilDestroy()
@Component({
  selector: 'app-verification-code-form',
  templateUrl: './verification-code-form.component.html',
  styleUrls: ['./verification-code-form.component.scss'],
})
export class VerificationCodeFormComponent implements OnInit {
  @ViewChild(NgOtpInputComponent) ngOtpInput: NgOtpInputComponent;

  @Output() code$ = new Subject<string>();
  @Output() switchAuthModalType$ = new Subject<AuthModalTypes>();

  @Input() email: string;

  readonly verificationCodeConfig = { length: 6, allowNumbersOnly: true, inputClass: 'op-otp-input', placeholder: '' };

  verificationCodeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private forgotPasswordService: ForgotPasswordService,
  ) {}

  get codeFormControl(): FormControl {
    return this.verificationCodeForm.get('code') as FormControl;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.listenCodeChange();
  }

  initializeForm(): void {
    this.verificationCodeForm = this.fb.group({
      code: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
    });
  }

  resendCode(): void {
    this.forgotPasswordService
      .sendVerificationCode(this.email)
      .pipe(first(), debounceTime(200), untilDestroyed(this))
      .subscribe();
  }

  /* istanbul ignore next */
  private listenCodeChange(): void {
    this.verificationCodeForm
      .get('code')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        filter(() => this.ngOtpInput.formCtrl.valid),
        switchMap(() => this.forgotPasswordService.checkVerificationCode(this.email, this.ngOtpInput.formCtrl.value)),
        tap((result: MutationResult<Password.ValidateCode.MutationResponse>) => {
          if (result.data?.validatePasswordCode?.message) {
            this.toastService.presentToast(result.data.validatePasswordCode.message);
          }
        }),
        filter(
          (response: MutationResult<Password.ValidateCode.MutationResponse>) =>
            !!response.data?.validatePasswordCode?.success,
        ),
        tap(() => this.code$.next(this.ngOtpInput.formCtrl.value)),
        tap(() => this.switchAuthModalType$.next(AuthModalTypes.UPDATE_PASSWORD)),
        catchError((error: unknown) => of()),
      )
      .subscribe();
  }
}
