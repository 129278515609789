import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Register } from '@op-types/register.model';
import { AuthenticationService } from '../../services/authentication';
import { RegisterMutation } from '../../graphql/mutations/register.mutation';

@Injectable()
export class SignUpService {
  constructor(
    private registerMutation: RegisterMutation,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
  ) {}

  /* istanbul ignore next */
  get deviceName(): string {
    return `${this.deviceDetectorService.browser}|${this.deviceDetectorService.os}` ?? 'Unknown';
  }

  registerAccount(
    firstName: string,
    lastName: string,
    contactNumber: string,
    email: string,
    password: string,
    trialSubscription: boolean,
  ): Observable<void> {
    return this.registerMutation
      .mutate({
        email,
        password,
        deviceName: this.deviceName,
        firstName,
        lastName,
        contactNumber,
        trialSubscription,
      })
      .pipe(
        first(),
        switchMap(
          /* istanbul ignore next */
          (response: MutationResult<Register.Response>) => {
            this.authenticationService.setUser(response?.data?.register?.user ?? null);

            return this.authenticationService.setToken(response?.data?.register?.token ?? '');
          },
        ),
      );
  }
}
