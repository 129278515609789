<ion-app>
  <app-menu></app-menu>
  <ion-header>
    <ion-toolbar>
      <app-header></app-header>
    </ion-toolbar>
  </ion-header>
  <ion-content padding>
    <ion-router-outlet id="main-content" class="op"></ion-router-outlet>
  </ion-content>
</ion-app>
