import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { Password } from '@op-types/index';

@Injectable()
export class ForgotPasswordMutation extends Mutation<
  Password.Forgot.MutationResponse,
  Password.Forgot.MutationVariables
> {
  document = gql`mutation ForgotPassword($email: String!) {forgotPassword(inputs: { email: $email }) {success,message}  }`;
}
